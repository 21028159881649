import payload_plugin_QMTjHnjnNX from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/@pinia+nuxt@0.10.0_magicast@0.3.5_pinia@3.0.0_typescript@5.7.3_vue@3.5.13_typescript@5.7.3__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_9is72cvoEi from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.4_@electric-sql+pglite@0.2.17_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_@_eztycv22uc3dzcxalimks4wiiu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_2E09VCcsXp from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.4_@electric-sql+pglite@0.2.17_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_@_eztycv22uc3dzcxalimks4wiiu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_DNFPVfCpR2 from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.4_@electric-sql+pglite@0.2.17_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_@_eztycv22uc3dzcxalimks4wiiu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_aO5SNX0fjF from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.4_@electric-sql+pglite@0.2.17_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_@_eztycv22uc3dzcxalimks4wiiu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_pwUyOqK7nX from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.4_@electric-sql+pglite@0.2.17_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_@_eztycv22uc3dzcxalimks4wiiu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_C4Fc65YSRs from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.4_@electric-sql+pglite@0.2.17_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_@_eztycv22uc3dzcxalimks4wiiu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_E4lj6Uig0C from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.4_@electric-sql+pglite@0.2.17_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_@_eztycv22uc3dzcxalimks4wiiu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_97OnrOg2N5 from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/@pinia+nuxt@0.10.0_magicast@0.3.5_pinia@3.0.0_typescript@5.7.3_vue@3.5.13_typescript@5.7.3__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/bgrand/Documents/Projects/live-display/admin/.nuxt/components.plugin.mjs";
import prefetch_client_QaSDv7aU05 from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt@3.15.4_@electric-sql+pglite@0.2.17_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.4_@_eztycv22uc3dzcxalimks4wiiu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_9byi5YLnFo from "/home/bgrand/Documents/Projects/live-display/node_modules/.pnpm/nuxt-quasar-ui@2.1.12_@quasar+extras@1.16.17_magicast@0.3.5_quasar@2.17.7/node_modules/nuxt-quasar-ui/dist/runtime/plugin.js";
import airbrake_H5MbEqRsNX from "/home/bgrand/Documents/Projects/live-display/shared/plugins/airbrake.ts";
import firebase_MrRCoKgj2U from "/home/bgrand/Documents/Projects/live-display/shared/plugins/firebase.ts";
import luxon_VlqPBC7NBo from "/home/bgrand/Documents/Projects/live-display/shared/plugins/luxon.ts";
import route_xWBYLf55o4 from "/home/bgrand/Documents/Projects/live-display/shared/plugins/route.ts";
export default [
  payload_plugin_QMTjHnjnNX,
  revive_payload_client_9is72cvoEi,
  unhead_2E09VCcsXp,
  router_DNFPVfCpR2,
  payload_client_aO5SNX0fjF,
  navigation_repaint_client_pwUyOqK7nX,
  check_outdated_build_client_C4Fc65YSRs,
  chunk_reload_client_E4lj6Uig0C,
  plugin_vue3_97OnrOg2N5,
  components_plugin_KR1HBZs4kY,
  prefetch_client_QaSDv7aU05,
  plugin_9byi5YLnFo,
  airbrake_H5MbEqRsNX,
  firebase_MrRCoKgj2U,
  luxon_VlqPBC7NBo,
  route_xWBYLf55o4
]